<template>
  <div class="booking-experience-info">
    <div class="pl-lg-9 px-sm-7 px-3 py-sm-5 py-3 d-flex gray lighten-4" v-if="data.lead_status">
      <div class="d-flex align-center">
        <div class="text--text me-4">Lead status:</div>
        <v-card flat width="100" height="32" :class="getColor(data.lead_status)" class="status-block rounded-pill white--text">
          {{ data.lead_status }}
        </v-card>
      </div>
      <v-spacer></v-spacer>
      <v-btn @click="action" v-if="data.lead_status == 'reject'" depressed outlined color="error" class="rounded">Reject</v-btn>
      <v-btn @click="action" v-if="data.lead_status == 'closed'" depressed color="primary" class="rounded me-3">Close</v-btn>
    </div>
    <v-divider v-if="data.lead_status"></v-divider>
    <div class="pl-lg-9 px-sm-7 px-3 py-sm-5 py-3">
      <div class="d-flex align-center">
        <div class="d-flex align-center">
          <v-avatar color="gray lighten-2" size="52">
            <v-img v-if="data.customer.picture != null" :src="data.customer.picture"></v-img>
            <div v-else class="d-flex align-center">
              <v-img class="gray-filter" contain width="36" height="36" src="@/assets/icon/user.svg"></v-img>
            </div>
          </v-avatar>
          <div class="ms-3">
            <div class="text-h6 font-weight-bold">{{ data.customer.first_name }} {{ data.customer.last_name }}</div>
            <div class="text--text">
              Registered at:
              {{
                new Date(data.created_at).toLocaleString('en-GB', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                })
              }}
            </div>
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-card flat width="100" height="32" :class="getColor(data.status)" class="status-block rounded-pill white--text">
          {{ data.status }}
        </v-card>
      </div>
      <div v-if="data.customer.phone_number || data.customer.email">
        <div class="mt-5 font-weight-bold">Customet details</div>
        <div class="text--text mt-2">{{ data.customer.phone_number }}</div>
        <div class="text--text mt-2">{{ data.customer.email }}</div>
      </div>
    </div>
    <v-divider></v-divider>
    <div class="pl-lg-9 px-sm-7 px-3 py-sm-5 py-3 d-flex width100">
      <v-img
        class="me-7 rounded-lg"
        max-width="150"
        height="150"
        cover
        v-if="data.travel_experience.picture"
        :src="data.travel_experience.picture"
      ></v-img>
      <div class="width100">
        <div class="text-h6 font-weight-bold">{{ data.travel_experience.title }}</div>
        <div class="mb-3">{{ data.travel_experience.number }}</div>
        <div v-if="data.expert" class="d-flex mb-3">
          <v-avatar color="gray lighten-2" size="52">
            <v-img v-if="data.expert.picture != null" :src="data.expert.picture"></v-img>
            <div v-else class="d-flex align-center">
              <v-img class="gray-filter" contain width="36" height="36" src="@/assets/icon/user.svg"></v-img>
            </div>
          </v-avatar>
          <div class="ms-2">
            <div>{{ data.expert.first_name }} {{ data.expert.last_name }}</div>
            <div class="d-flex" v-if="+data.expert.rating">
              <v-rating
                empty-icon="mdi-star"
                full-icon="mdi-star"
                half-icon="mdi-star-half-full"
                readonly
                color="warning"
                half-increments
                background-color="grey lighten-1"
                size="18"
                :length="Math.ceil(+data.expert.rating)"
                :value="+data.expert.rating"
              ></v-rating>
              <div class="text--text">({{ data.expert.rating }})</div>
            </div>
          </div>
        </div>
        <div>{{ data.travel_experience.description }}</div>
        <div class="d-flex flex-wrap">
          <div class="mt-4 d-flex me-3">
            <v-img class="mt-1" cover max-width="24" height="24" src="@/assets/icon/point.svg"></v-img>
            <div class="ml-4">
              <div class="font-weight-bold">Destination points</div>
              <div v-for="(i, j) in data.destinations" :key="j" class="text--text mt-1">{{ i.country.name }}, {{ i.city.name }}</div>
            </div>
          </div>
          <v-spacer></v-spacer>
          <div class="mt-4 d-flex me-3" v-if="data.duration_hours">
            <v-img class="mt-1" cover max-width="24" height="24" src="@/assets/icon/clock.svg"></v-img>
            <div class="ml-4">
              <div class="font-weight-bold">Duration</div>
              <div class="text--text mt-1">{{ getDate(data.duration_hours) }}</div>
            </div>
          </div>
          <v-spacer></v-spacer>
        </div>
      </div>
    </div>
    <v-divider v-if="data.charged_reward_amount || data.mokaffa || data.tabby_amount"></v-divider>
    <div class="pl-lg-9 pa-sm-7 pa-3 item-content" v-if="data.charged_reward_amount || data.mokaffa || data.tabby_amount">
      <div class="font-weight-bold">Loyalty prorams</div>
      <div class="d-flex justify-space-between mt-3">
        <v-card outlined class="loyalty-block py-3 px-5 width100" max-width="245" v-if="data.charged_reward_amount">
          <div class="text--text">Digital wallet</div>
          <div class="d-flex text-h6 font-weight-bold price primary--text">
            <div>
              <v-icon class="pb-1 text-h6" color="error">mdi-arrow-down</v-icon> {{ (data.charged_reward_amount / 100).toFixed(2) }}
            </div>
          </div>
          <div class="text--text">Chargedd</div>
        </v-card>
        <v-card outlined class="loyalty-block py-3 px-5 width100" max-width="245" v-if="data.mokaffa">
          <div class="text--text">Mokafaa</div>
          <div class="text-uppercase text-h6 font-weight-bold price primary--text">
            {{ data.currency }} {{ (data.mokafaa_amount / 100).toFixed(2) }}
          </div>
          <div class="text--text">Redeemed {{ data.mokaffa.phone_number }}</div>
        </v-card>
        <v-card outlined class="loyalty-block py-3 px-5 width100" max-width="245" v-if="data.tabby_amount">
          <div class="text--text">Tabby</div>
          <div class="text-uppercase text-h6 font-weight-bold price primary--text">
            {{ data.currency }} {{ (data.tabby_amount / 100).toFixed(2) }}
          </div>
        </v-card>
      </div>
    </div>
    <v-divider></v-divider>
    <div class="pl-lg-9 px-sm-7 px-3 py-sm-4 py-3">
      <div class="d-flex flex-wrap">
        <div>
          <div class="text--text">Experience ({{ data.vat_included ? 'VAT incl.' : 'VAT not incl.' }})</div>
          <div class="text--text my-3">VAT</div>
          <div class="text--text">Service fee</div>
        </div>
        <v-spacer></v-spacer>
        <div class="text-end">
          <div class="text--text text-uppercase">{{ data.currency }} {{ data.travel_experience.price / 100 }}</div>
          <div class="text--text text-uppercase my-3">{{ data.currency }} {{ (data.vat / 100).toFixed(2) }}</div>
          <div class="text--text text-uppercase">{{ data.currency }} {{ (data.service_fee / 100).toFixed(2) }}</div>
        </div>
      </div>
      <v-divider style="border-style: dashed" class="my-3"></v-divider>
      <div class="d-flex align-center flex-wrap">
        <div class="text--text font-weight-bold">Total</div>
        <v-spacer></v-spacer>
        <div class="text-h6 text-uppercase font-weight-bold">{{ data.currency }} {{ (data.total / 100).toFixed(2) }}</div>
      </div>
      <v-card flat height="72" v-for="(item, i) in attachments" :key="item.id" class="my-5 d-flex align-center pe-4 ps-5 attachments-card">
        <div class="d-flex align-center width100">
          <div class="d-flex">
            <img :src="getIcon(item.type)" height="42" width="42" alt="file" />
            <div class="ms-4">
              <div class="font-weight-bold">{{ item.title }}</div>
              <div class="text-body-2 gray--text">{{ sizeFile(item.size_bytes) }}</div>
            </div>
          </div>
          <v-spacer></v-spacer>
          <div>
            <v-btn @click="downloadFile(item)" depressed class="file-btn rounded me-2" color="primary" outlined height="38" width="130">
              <img src="@/assets/icon/download.svg" alt="download" />Download
            </v-btn>
            <v-btn
              @click="(modalData = item), (modal = true), (idx = i)"
              depressed
              outlined
              color="error"
              class="rounded"
              width="48"
              height="38"
              icon
            >
              <img class="error-filter" src="@/assets/icon/delete.svg" alt="icon" />
            </v-btn>
          </div>
        </div>
      </v-card>
      <v-btn
        v-if="data.status == 'canceled' ? false : data.status == 'expired' ? false : true"
        @click="modalNew = true"
        color="primary"
        class="file-btn rounded mt-5"
        block
        height="72"
        outlined
      >
        <img class="primary-filter" src="@/assets/icon/upload.svg" height="24" alt="upload" />
        <span class="text-body-2 primary--text ms-2">Add attachment</span>
      </v-btn>
      <!-- <v-spacer></v-spacer>
      <v-btn
        v-if="data.status == 'canceled' ? false : data.status == 'expired' ? false : true"
        @click="invoice"
        color="gray lighten-2"
        width="192"
        height="44"
        outlined
      >
        <div class="d-flex align-center">
          <img class="black-filter" height="20" src="@/assets/icon/download.svg" alt="icon" />
          <div class="text--text">Invoice</div>
        </div>
      </v-btn> -->
    </div>
    <v-dialog v-model="modal" max-width="480">
      <v-card flat outlined class="pb-6 px-4">
        <div class="d-flex my-3">
          <div class="text-h6 font-weight-bold">Do you really want to remove {{ modalData.title }}?</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="modal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="text-end">
          <v-btn @click="removeAttachments" depressed width="106" height="30" class="error text-body-2 rounded">Remove</v-btn>
          <v-btn @click="modal = false" outlined class="ms-3 text-body-2 rounded" width="106" height="30">Cancel</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalNew" max-width="460">
      <v-card flat>
        <div outlined class="px-8 py-3">
          <div class="font-weight-bold my-5">Add attachment</div>
          <v-card
            flat
            height="72"
            max-width="298"
            :class="fileErrors.length ? 'error lighten-5' : 'gray lighten-4'"
            class="d-flex align-center justify-center rounded width100"
          >
            <div class="text-center link" @click="addFile" v-if="!file">
              <img class="black-filter" height="30" src="@/assets/icon/upload.svg" alt="icon" />
              <div class="font-weight-bold mt-n1">Upload file</div>
            </div>
            <div v-else class="rounded width100 d-flex align-center pe-4 ps-5">
              <v-btn depressed x-small icon class="close-btn gray lighten-3" @click="file = ''">
                <v-icon color="gray darken-5">mdi-close mdi-18px</v-icon>
              </v-btn>
              <div class="d-flex">
                <img :src="getIcon(file.type.split('/')[1] == 'pdf' ? 'pdf' : 'other')" height="42" width="42" alt="file" />
                <div class="ms-4">
                  <div class="font-weight-bold text-truncate width100" style="max-width: 190px">{{ file.name }}</div>
                  <div class="text-body-2 gray--text">{{ sizeFile(file.size) }}</div>
                </div>
              </div>
            </div>
          </v-card>
          <div class="error--text text-caption" v-if="fileErrors.length">{{ fileErrors.join(' ') }}</div>
          <v-card flat height="20" v-else></v-card>
          <div class="mb-5">Provide attachment details</div>
          <v-textarea
            class="field46"
            v-model="modalData.title"
            :error-messages="titleErrors"
            placeholder="Provide title"
            outlined
            dense
            required
            color="primary"
            auto-grow
            rows="1"
            row-height="36"
          ></v-textarea>
          <v-textarea
            class="field46"
            v-model="modalData.description"
            :error-messages="desErrors"
            placeholder="Enter description"
            outlined
            dense
            required
            color="primary"
            auto-grow
            rows="3"
            row-height="36"
          ></v-textarea>
          <div class="text-end py-7">
            <v-btn @click="modalNew = false" depressed class="me-3 rounded" outlined width="106" height="30" text color="gray lighten-1">
              <span class="black--text text-body-2">Cancel</span>
            </v-btn>
            <v-btn @click="addAttachments" depressed class="primary rounded text-body-2" width="106" height="30">Done</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: false,
      modalNew: false,
      idx: '',
      file: '',
      modalData: {},
      error: [],
    };
  },
  methods: {
    notifi(btn) {
      if (btn == 'remove') {
        this.$notify({ title: 'Done!', message: 'File was successfully removed', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'add') {
        this.$notify({ title: 'Done!', message: 'File was successfully added', horizontalAlign: 'center', verticalAlign: 'top' });
      }
    },
    getIcon(icon) {
      return require(`@/assets/icon/file/${icon}.svg`);
    },
    sizeFile(s) {
      if (s <= 1024) {
        return `${s} B`;
      } else if (s / 1024 <= 1024) {
        return `${(s / 1024).toFixed(2)} Kb`;
      } else {
        return `${(s / 1024 / 1024).toFixed(2)} Mb`;
      }
    },
    addFile() {
      const input = document.createElement('input');
      const self = this;
      input.setAttribute('type', 'file');
      input.addEventListener('change', function() {
        if (this.files && this.files[0]) {
          self.file = this.files[0];
          var reader = new FileReader();
          reader.onload = () => {};
          reader.readAsDataURL(this.files[0]);
        }
      });
      input.click();
    },
    async invoice() {
      await this.$store.dispatch('getBookingExperienceItemInvoice');
    },
    getColor(s) {
      if (s == 'new' || s == 'paid') {
        return 'success';
      } else if (s == 'rejected' || s == 'canceled') {
        return 'error';
      } else if (s == 'opened' || s == 'closed') {
        return 'primary';
      } else {
        return 'gray';
      }
    },
    getDate(date) {
      if (date > 24) {
        return `${Math.floor(date / 24)} ${Math.floor(date / 24) > 1 ? 'Days' : 'Day'}`;
      } else if (date % 24) {
        return `${date} ${date > 1 ? 'Hours' : 'Hour'}`;
      } else {
        return 'Unknown';
      }
    },
    async action() {
      await this.$store.dispatch('actionBookingExperienceItem');
    },
    async addAttachments() {
      this.error = [];
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('title', this.modalData.title);
      formData.append('description', this.modalData.description);
      await this.$store
        .dispatch('addBookingExperienceItemAttachments', formData)
        .then((res) => {
          this.attachments.push(res.data);
          this.modalNew = false;
          this.notifi('add');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    downloadFile(item) {
      const link = document.createElement('a');
      link.download = item.title;
      link.target = '_blank';
      link.href = item.url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async removeAttachments() {
      await this.$store.dispatch('removeBookingExperienceItemAttachments', this.modalData.id).then(() => {
        this.attachments.splice(this.idx, 1);
        this.modal = false;
        this.notifi('remove');
      });
    },
  },
  watch: {
    modalNew() {
      this.error = [];
      this.file = '';
      this.modalData = {};
    },
  },
  computed: {
    data() {
      return this.$store.getters.bookingExperience;
    },
    role() {
      return this.$store.getters.profile.role;
    },
    attachments() {
      return this.$store.getters.bookingExperienceAttachments;
    },
    fileErrors() {
      const errors = [];
      this.error.find((item) => item == 'file__required') && errors.push('Please select file');
      this.error.find((item) => item == 'file__invalid') && errors.push('Selected file is not valid');
      return errors;
    },
    titleErrors() {
      const errors = [];
      this.error.find((item) => item == 'title__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'title__invalid') && errors.push('Provided title is not valid');
      return errors;
    },
    desErrors() {
      const errors = [];
      this.error.find((item) => item == 'description__required') && errors.push('Please enter description');
      this.error.find((item) => item == 'description__invalid') && errors.push('Provided description is not valid');
      return errors;
    },
  },
};
</script>

<style lang="scss">
.booking-experience-info {
  .status-block {
    font-weight: 700;
    line-height: 32px !important;
    text-align: center;
    text-transform: capitalize;
  }
  .loyalty-block {
    background: var(--v-gray-lighten4);
    .price {
      margin-top: 3px;
      margin-bottom: 3px;
    }
  }
  .attachments-card {
    border: 1px solid var(--v-gray-lighten1);
    background: var(--v-gray-lighten4);
    border-radius: 8px;
  }
  .file-btn {
    background: var(--v-primary-lighten3) !important;
  }
  .v-btn.error--text {
    background: var(--v-error-lighten5);
  }
}
</style>
