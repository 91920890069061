<template>
  <div class="booking-experience-booking">
    <div v-if="id.linked_trip_booking">
      <div class="pl-lg-9 px-sm-7 px-3 py-sm-5 py-3 d-flex">
        <div class="d-flex align-center">
          <v-avatar color="gray lighten-2" size="52">
            <v-img v-if="data.customer.picture != null" :src="data.customer.picture"></v-img>
            <div v-else class="d-flex align-center">
              <v-img class="gray-filter" contain width="36" height="36" src="@/assets/icon/user.svg"></v-img>
            </div>
          </v-avatar>
          <div class="ms-3">
            <router-link v-if="role != 'manager'" class="text-h6 font-weight-bold black--text" :to="`/customers/${data.customer.id}`">
              {{ data.customer.first_name }} {{ data.customer.last_name }}
            </router-link>
            <div v-else class="text-h6 font-weight-bold">{{ data.customer.first_name }} {{ data.customer.last_name }}</div>
            <div class="text--text">
              Created at:
              {{
                new Date(data.created_at).toLocaleString('en-GB', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                })
              }}
            </div>
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-btn :to="`/trip-bookings/${id.linked_trip_booking}`" width="190" height="38" class="rounded" outlined color="gray lighten-3">
          <v-icon color="black">mdi-link</v-icon><span class="text-body-2 black--text">Open in Trip Bookings</span>
        </v-btn>
      </div>
      <v-divider></v-divider>
      <div class="pl-lg-9 px-sm-7 px-3 py-sm-5 py-3 d-flex">
        <v-img class="me-7 rounded-lg" max-width="150" height="150" cover v-if="data.trip.picture" :src="data.trip.picture"></v-img>
        <div>
          <div class="text-h6 font-weight-bold mb-4">
            {{ data.trip.title }}
            <v-btn
              :to="`/trips/${data.trip.id}/general`"
              target="_blank"
              height="28"
              min-width="28"
              class="px-0"
              outlined
              color="gray lighten-1"
            >
              <v-icon class="text-h6" color="primary lighten-1">mdi-link mdi-rotate-135</v-icon>
            </v-btn>
          </div>
          <div class="text--text mb-2">
            {{
              new Date(data.starts_at).toLocaleString('en-GB', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              })
            }}
            -
            {{
              new Date(data.ends_at).toLocaleString('en-GB', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              })
            }}
          </div>
          <div>{{ data.trip.description }}</div>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="pl-lg-9 pa-sm-7 pa-3 item-content">
        <div class="d-flex flex-wrap">
          <div class="text-h6 font-weight-bold">{{ data.plan_type ? data[`${data.plan_type}_plan`].title_en : '' }}</div>
          <!-- <v-spacer></v-spacer>
          <div>
            <span class="text--text">Plan total: </span>
            <span class="text-uppercase font-weight-bold">{{ data.currency }} {{ (data.plan_total / 100).toFixed(2) || '' }}</span>
          </div> -->
        </div>
        <div class="text-capitalize text--text mb-2 mt-n1">{{ data.plan_type }}</div>
        <div class="text-capitalize text--text mb-7">{{ data.plan_type ? data[`${data.plan_type}_plan`].short_description_en : '' }}</div>
        <v-card outlined class="px-4 py-5 mb-7" v-if="data.plan_type ? data[`${data.plan_type}_plan`].accommodation : false">
          <div class="font-weight-bold">Accomodation</div>
          <div class="text--text mb-3 d-flex">
            {{ data.plan_type ? data[`${data.plan_type}_plan`].accommodation.title_en : '' }}
            <v-rating
              empty-icon="mdi-star"
              full-icon="mdi-star"
              half-icon="mdi-star-half-full"
              readonly
              color="warning"
              size="16"
              :length="data.plan_type ? data[`${data.plan_type}_plan`].accommodation.stars : 0"
              :value="data.plan_type ? data[`${data.plan_type}_plan`].accommodation.stars : 0"
              v-if="data.plan_type ? data[`${data.plan_type}_plan`].accommodation.stars : 0"
            ></v-rating>
          </div>
          <div v-if="data.general_plan" class="mb-4">
            <div class="font-weight-bold">Room Type</div>
            <div class="text--text text-capitalize mb-3">{{ data.general_plan.accommodation.plan.type }}</div>
            <div class="font-weight-bold">Room count</div>
            <div class="text--text">{{ data.general_plan.accommodation.plan.seats }}</div>
          </div>
          <v-divider style="border-style: dashed"></v-divider>
          <div class="d-flex flex-wrap font-weight-bold mt-4 mb-1">
            <div>Total amount</div>
            <v-spacer></v-spacer>
            <span class="text-uppercase">
              {{ data.currency }} {{ data.plan_type ? (data[`${data.plan_type}_plan`].price / 100).toFixed(2) : '' }}
            </span>
          </div>
        </v-card>
        <div v-if="data.plan_type">
          <div v-if="data[`${data.plan_type}_plan`].components.length" class="font-weight-bold mb-4">Components</div>
          <div class="d-flex flex-wrap" v-for="i in data[`${data.plan_type}_plan`].components" :key="i.id">
            <div v-if="i.included"><v-icon class="pr-3 pb-1" color="success">mdi-check mdi-18px</v-icon>{{ i.component.title_en }}</div>
            <div v-if="!i.included"><v-icon class="pr-3 pb-1" color="error">mdi-close mdi-18px</v-icon>{{ i.component.title_en }}</div>
          </div>
        </div>
        <div class="d-flex flex-wrap mt-7" v-if="data.addons.length">
          <div class="font-weight-bold">Addons</div>
          <v-spacer></v-spacer>
          <div>
            <span class="text--text">Addons total:</span>
            <span class="text-uppercase font-weight-bold">{{ data.currency }} {{ (data.addons_total / 100).toFixed(2) || '' }}</span>
          </div>
        </div>
        <v-card class="py-2 px-3 d-flex flex-wrap" outlined v-for="i in data.addons" :key="i.key">
          <div>{{ i.title }}</div>
          <v-spacer></v-spacer>
          <div>{{ i.currency }} {{ (i.price / 100).toFixed(2) }}</div>
        </v-card>
        <div
          v-if="data.discount || data.charged_reward_amount || data.enrolled_reward_amount || data.mokaffa || data.anb || data.tabby_amount"
        >
          <div class="font-weight-bold mt-5">Loyalty prorams</div>
          <div class="d-flex flex-wrap mt-3 width100">
            <v-card outlined class="loyalty-block py-3 pl-6 pr-7 rounded-lg" v-if="data.discount">
              <div class="text--text">Discount</div>
              <div class="text-uppercase text-h5 font-weight-bold price primary--text">
                {{ data.currency }} {{ data.discount_amount / 100 }}
              </div>
              <div class="secondary--text">{{ data.discount.code }}</div>
            </v-card>
            <v-card
              outlined
              class="loyalty-block py-3 pl-6 pr-7 rounded-lg"
              v-if="data.charged_reward_amount || data.enrolled_reward_amount"
            >
              <div class="text--text">Digital wallet</div>
              <div class="d-flex text-h5 font-weight-bold price primary--text">
                <div class="mr-5"><v-icon class="pb-1" color="error">mdi-arrow-down</v-icon> {{ data.charged_reward_amount / 100 }}</div>
                <div><v-icon class="pb-1" color="success">mdi-arrow-up</v-icon> {{ data.enrolled_reward_amount / 100 }}</div>
              </div>
              <div class="text--text">Charged / Enrolled</div>
            </v-card>
            <v-card outlined class="loyalty-block py-3 pl-6 pr-7 rounded-lg" v-if="data.mokaffa">
              <div class="text--text">Mokafaa</div>
              <div class="text-uppercase text-h5 font-weight-bold price primary--text">
                {{ data.currency }} {{ data.mokafaa_amount / 100 }}
              </div>
              <div class="text--text">Redeemed {{ data.mokaffa.phone_number }}</div>
            </v-card>
            <v-card outlined class="loyalty-block py-3 pl-6 pr-7 rounded-lg" v-if="data.anb">
              <div class="text--text">ANB</div>
              <div class="text-uppercase text-h5 font-weight-bold price primary--text">{{ data.currency }} {{ data.anb.amount / 100 }}</div>
              <div class="text--text">Redeemed {{ data.anb.phone_number }}</div>
            </v-card>
            <v-card outlined class="loyalty-block py-3 pl-6 pr-7 rounded-lg" v-if="data.tabby_amount">
              <div class="text--text">Tabby</div>
              <div class="text-uppercase text-h5 font-weight-bold price primary--text">
                {{ data.currency }} {{ data.tabby_amount / 100 }}
              </div>
            </v-card>
          </div>
        </div>
      </div>
      <v-divider style="border-style: dashed" class="mt-12"></v-divider>
      <div class="pl-lg-9 px-sm-7 px-3 py-sm-4 py-3 d-flex flex-wrap">
        <v-btn
          v-if="data.status == 'canceled' ? false : data.status == 'expired' ? false : true"
          @click="invoice"
          class="rounded"
          color="gray lighten-2"
          width="166"
          height="30"
          outlined
        >
          <span class="text-body-2 black--text">Download invoice</span>
        </v-btn>
        <v-spacer></v-spacer>
        <div>
          <div class="text--text">Participants</div>
          <div class="text--text my-3">Plan total ({{ data.vat_included ? 'VAT incl.' : 'VAT not incl.' }})</div>
          <div class="text--text my-3" v-if="data.addons_total">Addons total ({{ data.vat_included ? 'VAT incl.' : 'VAT not incl.' }})</div>
          <div class="text--text">VAT</div>
          <div class="text--text">Service fee</div>
          <div>Total</div>
        </div>
        <v-spacer></v-spacer>
        <div>
          <div class="text--text">{{ data.adults }} adults, {{ data.children }} children, {{ data.infants }} infants</div>
          <div class="text--text text-uppercase my-3">{{ data.currency }} {{ (data.plan_total / 100).toFixed(2) }}</div>
          <div class="text--text text-uppercase my-3" v-if="data.addons_total">
            {{ data.currency }} {{ (data.addons_total / 100).toFixed(2) }}
          </div>
          <div class="text--text text-uppercase my-1"> {{ data.currency }} {{ (data.vat / 100).toFixed(2) }}</div>
          <div class="text--text text-uppercase">{{ data.currency }} {{ (data.service_fee / 100).toFixed(2) }}</div>
          <div class="text-h6 font-weight-bold mb-4 text-uppercase">{{ data.currency }} {{ (data.total / 100).toFixed(2) }}</div>
        </div>
      </div>
    </div>
    <v-card flat v-else class="d-flex align-center justify-center" min-height="70vh">
      <div class="text-center">
        <img src="@/assets/icon/empty-booking.svg" alt="icon" />
        <div class="text--text mt-4">Customer still did not purchase any related trip</div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$store.dispatch('getBookingItem', this.id.linked_trip_booking);
  },
  methods: {
    async invoice() {
      await this.$store.dispatch('getBookingItemInvoice', this.id.linked_trip_booking);
    },
  },
  computed: {
    id() {
      return this.$store.getters.bookingExperience;
    },
    data() {
      return this.$store.getters.booking;
    },
    role() {
      return this.$store.getters.profile.role;
    },
  },
};
</script>

<style lang="scss">
#app .booking-experience-booking {
  .loyalty-block {
    background: var(--v-gray-lighten4);
    .price {
      margin-top: 3px;
      margin-bottom: 3px;
    }
  }
}
</style>
