<template>
  <div class="booking-experience-request">
    <div v-if="id.linked_trip_request">
      <div class="pl-lg-9 pa-sm-7 pa-3 item-content d-flex">
        <div class="d-flex align-center">
          <v-avatar color="gray lighten-2" size="52">
            <v-img v-if="data.person.picture != null" :src="data.person.picture"></v-img>
            <div v-else class="d-flex align-center">
              <v-img class="gray-filter" contain width="36" height="36" src="@/assets/icon/user.svg"></v-img>
            </div>
          </v-avatar>
          <div class="ms-3">
            <div class="text-h6 font-weight-bold">{{ data.person.first_name }} {{ data.person.last_name }}</div>
            <div class="text--text">
              Requested at:
              {{
                new Date(data.submitted_at).toLocaleString('en-GB', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                })
              }}
            </div>
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-btn :to="`/trips-requests/${id.linked_trip_request}`" width="190" height="38" class="rounded" outlined color="gray lighten-3">
          <v-icon color="black">mdi-link</v-icon><span class="text-body-2 black--text">Open in Trip Requests</span>
        </v-btn>
      </div>
      <v-divider></v-divider>
      <div class="pl-lg-9 pa-sm-7 pa-3 item-content">
        <div class="d-flex flex-md-row flex-column">
          <div class="me-md-3 width50">
            <div class="font-weight-bold">Departure point</div>
            <v-card flat height="46" class="gray lighten-4 px-3 d-flex align-center mt-2">
              <div class="text--text text-truncate">
                {{ data.departure_country ? data.departure_country.name : '-' }}, {{ data.departure_city ? data.departure_city.name : '-' }}
              </div>
            </v-card>
            <!-- <v-card v-if="data.departure_airport" flat height="46" class="gray lighten-4 px-3 d-flex align-center mt-2">
              <div class="text--text text-truncate">{{ data.departure_airport.name_en }}</div>
            </v-card> -->
            <div v-if="!!data.destinations" class="font-weight-bold mt-5">Destination points</div>
            <v-card v-for="i in data.destinations" :key="i.city.name" flat height="46" class="gray lighten-4 px-3 d-flex align-center mt-2">
              <div class="text--text text-truncate">{{ i.country.name }}, {{ i.city.name }}</div>
            </v-card>
            <div class="font-weight-bold mt-5">Who will travel</div>
            <v-card flat height="46" class="gray lighten-4 px-3 d-flex align-center mt-2">
              <div class="d-flex text-truncate">
                <v-img class="gray-filter me-1" contain max-width="20" max-height="20" src="@/assets/icon/user.svg"></v-img>
                <div class="text--text text-truncate">{{ data.adults }} adult, {{ data.children }} child, {{ data.infants }} infant</div>
              </div>
            </v-card>
            <div class="font-weight-bold mt-5">Trip dates</div>
            <v-card flat height="46" class="gray lighten-4 px-3 d-flex align-center mt-2">
              <div class="text--text text-truncate">
                <v-icon color="text lighten-1">mdi-calendar-range</v-icon>
                {{
                  new Date(data.date_from).toLocaleString('en-GB', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  })
                }}
                -
                {{
                  new Date(data.date_to).toLocaleString('en-GB', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  })
                }}
              </div>
            </v-card>
            <div class="font-weight-bold mt-5">Budget</div>
            <v-card flat height="46" class="gray lighten-4 px-3 d-flex align-center mt-2">
              <div class="text--text text-uppercase text-truncate">{{ data.budget_currency }} {{ data.budget / 100 }}</div>
            </v-card>
          </div>
          <div class="ms-md-3 width50">
            <div class="font-weight-bold">Service option</div>
            <v-card flat height="46" class="gray lighten-4 px-3 d-flex align-center mt-2">
              <div class="text--text text-capitalize text-truncate">{{ data.service_option }}</div>
            </v-card>
            <div class="font-weight-bold mt-5">Transport option</div>
            <v-card flat height="46" class="gray lighten-4 px-3 d-flex align-center mt-2">
              <div class="text--text text-capitalize text-truncate">{{ data.transport_option }}</div>
            </v-card>
            <div class="font-weight-bold mt-5">Transfer option</div>
            <v-card flat height="46" class="gray lighten-4 px-3 d-flex align-center mt-2">
              <div class="text--text text-capitalize text-truncate">{{ data.transfer_option || '-' }}</div>
            </v-card>
            <div class="font-weight-bold mt-5">Hotel class</div>
            <v-card flat height="46" class="gray lighten-4 px-3 d-flex align-center mt-2">
              <div class="text--text text-capitalize text-truncate">
                {{ data.hotel ? `${data.hotel.split('_').join(' ')} stars` : '-' }}
              </div>
            </v-card>
            <div class="font-weight-bold mt-5">Aditional notes</div>
            <v-card flat min-height="46" class="gray lighten-4 px-3 py-2 d-flex align-center mt-2">
              <div class="text--text">{{ data.additional_notes }}</div>
            </v-card>
          </div>
        </div>
      </div>
      <div v-if="data.plan">
        <v-divider></v-divider>
        <div class="pl-lg-9 pa-sm-7 pa-3 item-content">
          <div class="text-h6 font-weight-bold mb-3">Trip offer</div>
          <div class="font-weight-bold">Trip</div>
          <div class="text--text mb-3">Select Trip from the list of available trips</div>
          <v-autocomplete
            v-model="data.plan.trip"
            style="max-width: 371px"
            :items="tripList"
            :error-messages="tripError"
            item-text="title"
            item-value="id"
            placeholder="Select trip"
            return-object
            outlined
            dense
            class="field46"
            height="46"
            :disabled="data.status == 'finished'"
          ></v-autocomplete>
          <div class="d-flex flex-md-row flex-column align-md-end">
            <div class="me-md-3">
              <div class="font-weight-bold">Nights</div>
              <div class="text--text">Number of nights</div>
              <v-text-field
                class="field46"
                style="max-width: 186px"
                v-model="data.plan.nights"
                :error-messages="nightsErrors"
                placeholder="0"
                type="number"
                outlined
                dense
                required
                color="primary"
                height="46"
                :disabled="data.status == 'finished'"
              ></v-text-field>
            </div>
            <div class="mt-md-0 mt-n2 ms-md-3">
              <div class="font-weight-bold">Total price</div>
              <div class="text--text">Provide the price of the Plan</div>
              <v-text-field
                class="field46"
                style="max-width: 186px"
                v-model="data.plan.priceR"
                :error-messages="priceErrors"
                placeholder="0"
                suffix="SAR"
                outlined
                dense
                required
                color="primary"
                height="46"
                :disabled="data.status == 'finished'"
              ></v-text-field>
            </div>
          </div>
          <div class="font-weight-bold">Plan title</div>
          <div class="text--text mb-3">Provide individual plan option for custommer</div>
          <div class="d-flex flex-md-row flex-column multi-direct">
            <v-text-field
              class="field46 width100 me-md-3"
              v-model="data.plan.title_en"
              placeholder="Title"
              :error-messages="titleEnErrors"
              outlined
              dense
              required
              color="primary"
              height="46"
              :disabled="data.status == 'finished'"
            ></v-text-field>
            <v-text-field
              class="field46 width100 mt-md-0 mt-n2 ms-md-3"
              v-model="data.plan.title_ar"
              placeholder="عنوان"
              :error-messages="titleArErrors"
              outlined
              dense
              required
              color="primary"
              height="46"
              :disabled="data.status == 'finished'"
            ></v-text-field>
          </div>
          <div class="d-flex flex-md-row flex-column mt-n2 multi-direct">
            <v-text-field
              class="field46 width100 me-md-3"
              v-model="data.plan.short_description_en"
              placeholder="Short description"
              :error-messages="desShortEnErrors"
              outlined
              dense
              required
              color="primary"
              height="46"
              :disabled="data.status == 'finished'"
            ></v-text-field>
            <v-text-field
              class="field46 width100 mt-md-0 mt-n2 ms-md-3"
              v-model="data.plan.short_description_ar"
              placeholder="عنوان"
              :error-messages="desShortArErrors"
              outlined
              dense
              required
              color="primary"
              height="46"
              :disabled="data.status == 'finished'"
            ></v-text-field>
          </div>
          <div class="font-weight-bold">Start date</div>
          <div class="text--text mb-3">Select the available day when customer can booking this plan</div>
          <div class="d-flex flex-md-row flex-column align-md-end">
            <div class="width100 me-md-3">
              <div class="font-weight-bold text-body-2">Date</div>
              <vc-date-picker
                locale="en"
                :input-debounce="500"
                v-model="data.plan.start_date"
                :masks="masks"
                :model-config="modelConfig"
                :minute-increment="5"
                mode="dateTime"
                is24hr
                :first-day-of-week="2"
                is-required
                color="blue"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <v-text-field
                    class="field46"
                    :value="inputValue"
                    v-on="inputEvents"
                    :error-messages="dateErrors"
                    placeholder="Date"
                    outlined
                    dense
                    color="primary"
                    height="46"
                    readonly
                    :disabled="data.status == 'finished'"
                  >
                    <template v-slot:append>
                      <v-img class="mt-2" src="@/assets/icon/calendar.svg"></v-img>
                    </template>
                  </v-text-field>
                </template>
              </vc-date-picker>
            </div>
            <div class="width100 mt-md-0 mt-n2 ms-md-3">
              <div class="font-weight-bold text-body-2">Duration, hours</div>
              <v-text-field
                class="field46"
                v-model="data.plan.duration_hours"
                :error-messages="durationErrors"
                placeholder="0"
                type="number"
                outlined
                dense
                required
                color="primary"
                height="46"
                :disabled="data.status == 'finished'"
              ></v-text-field>
            </div>
          </div>
          <div class="font-weight-bold">Seats</div>
          <div class="text--text mb-3">Select the available day when customer can booking this plan</div>
          <div class="d-flex flex-md-row flex-column align-md-end">
            <div>
              <div class="font-weight-bold text-body-2">Adults</div>
              <v-text-field
                class="field46"
                style="max-width: 186px"
                v-model="data.adults"
                placeholder="0"
                type="number"
                outlined
                dense
                required
                color="primary"
                height="46"
                :disabled="data.status == 'finished'"
              ></v-text-field>
            </div>
            <div class="mx-md-6 mt-md-0 mt-n2">
              <div class="font-weight-bold text-body-2">Children</div>
              <v-text-field
                class="field46"
                style="max-width: 186px"
                v-model="data.children"
                placeholder="0"
                type="number"
                outlined
                dense
                required
                color="primary"
                height="46"
                :disabled="data.status == 'finished'"
              ></v-text-field>
            </div>
            <div class="mt-md-0 mt-n2">
              <div class="font-weight-bold text-body-2">Infants</div>
              <v-text-field
                class="field46"
                style="max-width: 186px"
                v-model="data.infants"
                placeholder="0"
                type="number"
                outlined
                dense
                required
                color="primary"
                height="46"
                :disabled="data.status == 'finished'"
              ></v-text-field>
            </div>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="pl-lg-9 pa-sm-7 pa-3 item-content">
          <div class="font-weight-bold text-h6 mb-5">Accomodations</div>
          <v-card outlined class="mt-2 rounded-lg" v-for="(item, i) in data.plan.accommodations" :key="i + 't'">
            <v-card outlined class="accommo-card rounded-lg">
              <div class="px-5 py-4">
                <div>
                  {{ item.destination.city.name }}, {{ item.destination.country.name }}:
                  {{ item.accommodation ? item.accommodation.title_en : 'Not selected' }}
                </div>
                <div class="accommo-card_arrow" v-if="item.accommodation || data.status != 'finished'">
                  <v-btn color="text" height="28" width="28" rounded icon @click="openAccommo(i)">
                    <v-icon>{{ window.some((j) => j == i) ? 'mdi-chevron-down' : 'mdi-chevron-right' }}</v-icon>
                  </v-btn>
                </div>
              </div>
              <div v-if="window.some((j) => j == i)">
                <table v-if="item.accommodation">
                  <tr>
                    <td>Hotel</td>
                    <td class="d-flex justify-space-between">
                      <div>{{ item.accommodation.title_en }}</div>
                      <div>{{ item.accommodation.title_ar }}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>Nights</td>
                    <td>{{ item.nights }}</td>
                  </tr>
                </table>
              </div>
            </v-card>
            <div class="text-end py-3 px-4" v-if="window.some((j) => j == i) && data.status != 'finished'">
              <v-btn
                @click="(editModal = true), (dataModal = Object.assign({}, item))"
                class="rounded"
                outlined
                color="primary"
                width="156"
                height="30"
              >
                <span class="text-body-2">{{ item.accommodation ? 'Edit Accommodation' : 'Add Accommodation' }}</span>
              </v-btn>
              <v-btn
                v-if="item.accommodation"
                @click="updateItem('remove')"
                width="196"
                height="30"
                outlined
                class="rounded ms-4"
                color="error"
              >
                <span class="text-body-2">Remove Accommodation</span>
              </v-btn>
            </div>
          </v-card>
        </div>
        <v-divider v-if="data.plan.components.length"></v-divider>
        <div class="pl-lg-9 pa-sm-7 pa-3 item-content" v-if="data.plan.components.length">
          <div class="font-weight-bold mb-3">Components</div>
          <div v-for="i in data.plan.components" :key="i.id" class="d-flex flex-md-row flex-column align-md-center my-4">
            <v-switch
              @change="updateComponent(i)"
              v-model="i.included"
              color="primary"
              class="white--text mt-0 mb-md-0 mb-2"
              hide-details
              inset
              :disabled="i.id == 1 || data.status == 'finished'"
            ></v-switch>
            <v-btn
              v-if="i.id != 1 && data.status != 'finished'"
              @click="removeCom(i)"
              icon
              class="d-md-none"
              style="position: absolute; right: 20px"
            >
              <v-icon color="gray lighten-1">mdi-close</v-icon>
            </v-btn>
            <v-select
              @change="i.id == 1 ? addComponent(i) : updateComponent(i)"
              v-model="i.component"
              :items="componentList"
              item-text="title_en"
              item-value="id"
              placeholder="Add new"
              outlined
              dense
              hide-details
              return-object
              class="field46"
              height="46"
              :disabled="data.status == 'finished'"
            >
              <template v-slot:selection="{ item }">
                <div class="d-flex align-center justify-space-between width100">
                  <div>{{ item.title_en }}</div>
                  <div class="gray--text text--lighten-3 mx-2">|</div>
                  <div class="d-flex align-center">
                    <div>{{ item.title_ar }}</div>
                    <div class="gray--text text--lighten-2 ps-2">|</div>
                  </div>
                </div>
              </template>
              <template v-slot:item="{ item }">
                <div class="d-flex align-center justify-space-between width100">
                  <div>{{ item.title_en }}</div>
                  <div class="gray--text text--lighten-3 mx-2">|</div>
                  <div>{{ item.title_ar }}</div>
                </div>
              </template>
            </v-select>
            <div class="d-md-block d-none">
              <v-btn v-if="i.id != 1 && data.status != 'finished'" @click="removeCom(i)" icon class="ms-2">
                <v-icon color="gray lighten-1">mdi-close</v-icon>
              </v-btn>
              <v-card v-else tile flat width="36" height="36" class="ms-2"></v-card>
            </div>
          </div>
        </div>
      </div>
      <div v-if="data.status == 'processed'">
        <v-divider></v-divider>
        <div class="pl-lg-9 px-sm-7 px-3 py-3 text-end">
          <v-btn @click="rejectRequest" width="126" height="30" outlined class="rounded me-4" color="gray">
            <span class="error--text text-body-2">Reject</span>
          </v-btn>
          <v-btn @click="updateRequest" depressed color="primary" class="text-body-2 rounded" width="106" height="30">Save</v-btn>
        </div>
      </div>
      <div v-if="data.status == 'submitted'">
        <v-divider></v-divider>
        <div class="pl-lg-9 px-sm-7 px-3 py-3 text-end">
          <v-btn @click="rejectRequest" width="126" height="30" outlined class="rounded me-4" color="gray">
            <span class="error--text text-body-2">Reject</span>
          </v-btn>
          <v-btn
            @click="data.plan ? saveRequest() : createItem()"
            depressed
            color="primary"
            class="text-body-2 rounded"
            width="106"
            height="30"
          >
            Create offer
          </v-btn>
        </div>
      </div>
    </div>
    <v-card flat v-else class="d-flex align-center justify-center" min-height="70vh">
      <div class="text-center">
        <img src="@/assets/icon/empty-request.svg" alt="icon" />
        <div class="text--text mt-4">Customer still did not submit custom trip request</div>
      </div>
    </v-card>
    <v-dialog v-model="editModal" persistent max-width="460">
      <v-card tile class="pt-5 pb-7 px-7">
        <div class="font-weight-bold text-h6 mb-5">{{ dataModal.accommodation_type ? 'Edit accommodation' : 'Add accommodation' }}</div>
        <div class="mb-2">Accommodation</div>
        <v-autocomplete
          v-model="dataModal.accommodation"
          placeholder="Select hotel"
          :items="accommodationList"
          item-text="title_en"
          item-value="id"
          return-object
          outlined
          dense
          class="field46"
          height="46"
        >
          <template v-slot:selection="data">
            {{ data.item.title_en }}
            <v-rating
              empty-icon="mdi-star"
              full-icon="mdi-star"
              half-icon="mdi-star-half-full"
              readonly
              class="ps-3"
              color="warning darken-1"
              size="16"
              :length="data.item.stars"
              :value="data.item.stars"
              v-if="data.item.stars"
            ></v-rating>
          </template>
          <template v-slot:item="data">
            {{ data.item.title_en }}
            <v-rating
              empty-icon="mdi-star"
              full-icon="mdi-star"
              half-icon="mdi-star-half-full"
              readonly
              class="ps-3"
              color="warning darken-1"
              size="16"
              :length="data.item.stars"
              :value="data.item.stars"
              v-if="data.item.stars"
            ></v-rating>
          </template>
        </v-autocomplete>
        <div class="mb-2 mt-n2">Night selector</div>
        <v-text-field
          v-model="dataModal.nights"
          placeholder="Select by title"
          outlined
          dense
          type="number"
          class="field46"
          height="46"
        ></v-text-field>
        <div class="text-end">
          <v-btn @click="editModal = false" width="106" height="30" outlined class="text-body-2 rounded me-3">Cancel</v-btn>
          <v-btn
            :disabled="!dataModal.accommodation || !dataModal.nights"
            @click="updateItem('')"
            depressed
            width="106"
            height="30"
            color="primary"
            class="text-body-2 rounded"
          >
            Create
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      modal: false,
      editModal: false,
      idAcc: null,
      country: '',
      city: '',
      dataModal: {},
      requestsList: [],
      masks: {
        inputDateTime24hr: 'MMM D, YYYY HH:mm',
      },
      modelConfig: {
        type: 'number',
        mask: 'ISO',
      },
      window: [],
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getRequestsItem', this.id.linked_trip_request);
    this.$store.dispatch('getTripRequestList', '');
    this.$store.dispatch('getTripTravelConponents');
    this.$store.dispatch('getAccommodationShort');
  },
  methods: {
    openAccommo(s) {
      if (this.window.some((i) => i == s)) {
        const idx = this.window.indexOf(+s);
        this.window.splice(idx, 1);
      } else {
        this.window.push(+s);
      }
    },
    createItem() {
      this.data.plan = { accommodations: [], trip: {}, components: [] };
    },
    async updateItem(i) {
      const data = new Object();
      data.destination = this.dataModal.id;
      data.accommodation = i ? null : this.dataModal.accommodation.id;
      data.nights = this.dataModal.nights;
      await this.$store.dispatch('setRequestsItemAccommo', { data: data, id: this.id.linked_trip_request }).then(() => {
        this.editModal = false;
      });
    },
    async searchTrip(i) {
      const data = new Object();
      data.travel_component = i.component.id;
      data.included = true;
      await this.$store.dispatch('getTripRequestList', data);
    },
    async addComponent(i) {
      const data = new Object();
      data.travel_component = i.component.id;
      data.included = true;
      await this.$store.dispatch('createRequestsItemCom', { data: data, id: this.id.linked_trip_request });
    },
    async updateComponent(i) {
      const data = new Object();
      data.travel_component = i.component.id;
      data.included = i.included;
      await this.$store.dispatch('updateRequestsItemCom', { id: i.id, data: data, idr: this.id.linked_trip_request });
    },
    async removeCom(i) {
      await this.$store.dispatch('removeRequestsItemCom', { id: i.id, idr: this.id.linked_trip_request });
    },
    async rejectRequest() {
      await this.$store.dispatch('rejectRequestsItem', this.id.linked_trip_request);
    },
    async saveRequest() {
      const data = new Object();
      data.trip = this.data.plan.trip.id;
      data.title_en = this.data.plan.title_en;
      data.title_ar = this.data.plan.title_ar;
      data.short_description_en = this.data.plan.short_description_en;
      data.short_description_ar = this.data.plan.short_description_ar;
      data.start_date = +this.data.plan.start_date;
      data.duration_hours = +this.data.plan.duration_hours;
      // data.accommodation = !!this.data.plan.accommodation ? this.data.plan.accommodation.id : this.idAcc;
      data.nights = +this.data.plan.nights;
      data.adults = +this.data.adults;
      data.children = +this.data.children;
      data.infants = +this.data.infants;
      data.price = +this.data.plan.priceR * 100;
      await this.$store
        .dispatch('createRequestsItem', { data: data, id: this.id.linked_trip_request })
        .then(() => {
          this.idAcc = null;
        })
        .catch((e) => {
          this.error = this.error = e.response.data.error;
        });
    },
    async updateRequest() {
      const data = new Object();
      data.trip = this.data.plan.trip.id;
      data.title_en = this.data.plan.title_en;
      data.title_ar = this.data.plan.title_ar;
      data.short_description_en = this.data.plan.short_description_en;
      data.short_description_ar = this.data.plan.short_description_ar;
      data.start_date = +this.data.plan.start_date;
      data.duration_hours = +this.data.plan.duration_hours;
      // data.accommodation = this.data.plan.accommodation ? this.data.plan.accommodation.id : this.idAcc;
      data.nights = +this.data.plan.nights;
      data.adults = +this.data.plan.adults;
      data.children = +this.data.plan.children;
      data.infants = +this.data.plan.infants;
      data.price = +this.data.plan.priceR * 100;
      await this.$store
        .dispatch('updateRequestsItem', { data: data, id: this.id.linked_trip_request })
        .then(() => {
          this.idAcc = null;
        })
        .catch((e) => {
          this.error = this.error = e.response.data.error;
        });
    },
  },
  computed: {
    id() {
      return this.$store.getters.bookingExperience;
    },
    data() {
      return this.$store.getters.requests;
    },
    role() {
      return this.$store.getters.profile.role;
    },
    componentList() {
      return this.$store.getters.tripTvComponent;
    },
    accommodationList() {
      return this.$store.getters.accommodationContentListShort;
    },
    tripList() {
      return this.$store.getters.tripRequestList;
    },
    tripError() {
      const errors = [];
      this.error.find((item) => item == 'trip__required') && errors.push('Please enter trip');
      this.error.find((item) => item == 'trip__invalid') && errors.push('Provided trip is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    titleEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_en__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'title_en__invalid') && errors.push('Provided title is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    titleArErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_ar__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'title_ar__invalid') && errors.push('Provided title is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    desShortEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'short_description_en__required') && errors.push('Please enter short description');
      this.error.find((item) => item == 'short_description_en__invalid') && errors.push('Provided short description is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    desShortArErrors() {
      const errors = [];
      this.error.find((item) => item == 'short_description_ar__invalid') && errors.push('Provided short description is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    dateErrors() {
      const errors = [];
      this.error.find((item) => item == 'start_date__required') && errors.push('Please enter date');
      this.error.find((item) => item == 'start_date__invalid') && errors.push('Provided date is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    durationErrors() {
      const errors = [];
      this.error.find((item) => item == 'duration_hours__required') && errors.push('Please enter duration hours');
      this.error.find((item) => item == 'duration_hours__invalid') && errors.push('Provided duration hours is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    nightsErrors() {
      const errors = [];
      this.error.find((item) => item == 'nights__required') && errors.push('Please enter nights');
      this.error.find((item) => item == 'nights__invalid') && errors.push('Provided nights is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    priceErrors() {
      const errors = [];
      this.error.find((item) => item == 'price__required') && errors.push('Please enter price');
      this.error.find((item) => item == 'price__invalid') && errors.push('Provided price is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setRequestsItem');
    this.$store.dispatch('setTripRequestList');
    this.$store.dispatch('setTripsItem');
    this.$store.dispatch('setAccommodationShort');
  },
};
</script>

<style lang="scss">
.booking-experience-request {
  .accommo-card {
    // border: 1px solid var(--v-gray-lighten2);
    background: var(--v-gray-lighten4);
    &_arrow {
      position: absolute;
      top: 16px;
      right: 20px;
      z-index: 5;

      .mdi::before {
        height: 24px !important;
      }
    }
    table {
      border-collapse: collapse;
      width: 100%;
    }
    td,
    th {
      border-top: thin solid #dddddd;
      padding: 12px 16px;
      color: black;
    }
    td:first-of-type {
      color: var(--v-text-base);
      width: 150px;
      border-right: thin solid #dddddd;
    }
  }
  .text-end {
    .v-btn.primary--text {
      background: var(--v-primary-lighten3);
    }
    .v-btn.error--text {
      background: var(--v-error-lighten5);
    }
  }
}
</style>
