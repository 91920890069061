<template>
  <div class="booking-experience-item">
    <div class="font-weight-bold">
      <router-link class="disabled--text" to="/experience-bookings">Experience Bookings</router-link>
      <v-icon color="disabled" class="text-h6">mdi-chevron-right</v-icon>
      <span class="black--text">{{ data.number }}</span>
    </div>
    <v-card class="mt-4" width="100%" outlined>
      <div class="d-flex wrap">
        <v-card color="item-menu py-2" flat>
          <div
            v-for="item in menuItems"
            :key="item.title"
            :class="link == item.link ? 'gray lighten-4 pl-3 pr-5' : 'px-5'"
            @click="clickMenu(item.link)"
            class="item-menu_item link"
          >
            <div>{{ item.title }}</div>
          </div>
        </v-card>
        <v-divider style="z-index: 90" vertical></v-divider>
        <div class="width100" v-if="link == 'general'">
          <Info />
        </div>
        <div class="width100" v-if="link == 'trip-booking'">
          <TripBooking />
        </div>
        <div class="width100" v-if="link == 'trip-request'">
          <TripRequest />
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import Info from '@/components/bookingExperience/Info';
import TripBooking from '@/components/bookingExperience/TripBooking';
import TripRequest from '@/components/bookingExperience/TripRequest';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  components: {
    Info,
    TripBooking,
    TripRequest,
  },
  data() {
    return {
      link: this.$route.params.tab || 'general',
      menuItems: [
        { link: 'general', title: 'General info' },
        { link: 'trip-booking', title: 'Linked Trip Booking' },
        { link: 'trip-request', title: 'Linked Trip Request' },
      ],
    };
  },
  mounted() {
    this.$store.dispatch('getBookingExperienceItem');
    this.$store.dispatch('getCountryList');
    this.$store.dispatch('getBookingExperienceItemAttachments');
  },
  methods: {
    clickMenu(l) {
      this.link = l;
      this.$router.push(`/experience-bookings/${this.$route.params.bookingId}/${l}`);
    },
  },
  computed: {
    data() {
      return this.$store.getters.bookingExperience;
    },
  },
  destroyed() {
    this.$store.dispatch('setBookingExperienceItem');
    this.$store.dispatch('setBookingExperienceItemAttachments');
  },
};
</script>
